@font-face {
    font-family: Pro;
    src: url("./fonts/SF-Pro-Text-Regular.otf") format("opentype");
}

@font-face {
    font-family: Pro-bold;
    src: url("./fonts/SF-Pro-Text-Bold.otf") format("opentype");
}

@font-face {
    font-family: Pro-italic;
    src: url("./fonts/SF-Pro-Text-RegularItalic.otf") format("opentype");
}

@font-face {
    font-family: Pro-heavy;
    src: url("./fonts/SF-Pro-Text-Heavy.otf") format("opentype");
}

@font-face {
    font-family: Pro-semibold;
    src: url("./fonts/SF-Pro-Text-Semibold.otf") format("opentype");
}

@font-face {
    font-family: Pro-light;
    src: url("./fonts/SF-Pro-Text-Light.otf") format("opentype");
}

h1{
  font-family: Pro-heavy;
  font-variant: small-caps;
}

body, button {
  font-family: Pro-light;
}
button {
  cursor: pointer;
}
.App {
  text-align: center;
}
button,label {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 8vmin;
  min-height: 100px;
  pointer-events: none;
}

h1,h2,p {
  margin: 10px;
}

/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/

.App-header {
  background-color: white;
  min-height: 2vh;
  color: #222;
}

.App-link {
  color: #222;
  background-color: whitesmoke;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#main-container{
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1100px;
  margin: auto;
}

input{
  min-width: 200px;
}
button.App-link{
  font-size: 1.1em;
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid #444;
  box-shadow: -5px 5px 10px rgb(23 19 43 / 15%);
  margin: auto;
}

button.App-link:hover {
  background-color: #111;
  color: white;
  transition-duration: .5s;
}

table {
  border: 1px solid DodgerBlue;
  margin-top: 15px;
  padding:10px;
  min-width: 100%;
  border-radius: 5px;
  box-shadow: -5px 5px 10px rgb(23 19 43 / 10%);
}
svg{margin:10px;}

th {
  background-color: DodgerBlue;
  color: white;
  font-family: Pro-heavy;
  padding: 5px;
  min-width: 100% !important;
}

td {
  background-color: whitesmoke;
  text-align: left;
  padding: 5px;
  min-width: 100% !important;
}
td:nth-child(1){
  color: #111;
  font-weight: 800;
}
td:nth-child(2){
  background-color: white;
  text-align: center;
}

.phone-table > tbody > tr > td:nth-child(2){
  min-width: 200px;
}

#search-input{
  padding: 10px;
  font-size: 14px;
  font-family: monospace;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  box-shadow: -5px 5px 10px rgb(5 5 43 / 25%);
}
.brand-btn {
    min-width: calc(50% - 40px);
    margin: 10px;
}
.brand-btn > button {
  width: 100%;
  margin: auto;
}
.company-link{
  font-variant: small-caps;
  font-family: Pro-semibold;
  color: #444;
  text-decoration: none;
}
.search-container {
  margin-bottom: 2.5%;
}

footer {
margin-top: 7.5%;
width: 100%;
min-height: 100px;
display: flex;
justify-content: center;
align-items: center;
color: white;
background-color: #111;
}

@media screen and (max-width: 769px){
#search-input{
  width: calc(100% - 20px);
}
.brand-btn {
  min-width: 90%;
}

}

@media screen and (min-width: 950px){
#search-input{
  min-width: 300px;
}

}





/*the container must be positioned relative:*/
.autocomplete {
  position: relative;
  display: block;

}
div.navbar > div {
  justify-content: space-around !important;
}
@media screen and (min-width:450px){
  div.navbar > div {
width: 70px;
position: absolute;
}
.App-logo{
  margin-top: 30px;
}
}
@media screen and (max-width:320px){
  #instructions{
  margin: auto !important;
}
}

@media screen and (max-width: 769px){
  .autocomplete {
    width: calc(93% - 20px);
    margin: auto !important;
  }
  .autocomplete-items div{
    max-width: calc(100% - 20px);
  }
}

input[type=text] {
  background-color: #f1f1f1;
  width: 100%;
}

input[type=submit] {
  background-color: DodgerBlue;
  color: #fff;
  cursor: pointer;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  padding: 10px;
  width: 100%;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}
#inst > p, #info > p {
  color: white;
  font-size:12px;
  font-family: Pro-bold;
}
#info > table > tbody > th, #info > table > tbody > tr > td {
  color: white;
  text-align: center;
  padding: 10px;
  background-color: #111;
}
.active{display: block !important;}
